/*
 * @Descripttion: 
 * @version: 
 * @Author:  Liu HongChao
 * @Date: 2023-07-16 22:49:51
 * @LastEditors:  Liu HongChao
 * @LastEditTime: 2024-01-11 15:40:18
 */
import $http from '@/utitls/request'
/**
 * 获取能耗排名信息
 */
export const GetEnergyRanking = (data) => {
    return $http.get('/api/DataRecord/GetEnergyRanking', { params: data })
}
/**
 * 获取耗电量信息
 */
export const GetPowerInfo = (data) => {
    return $http.get('/api/DataRecord/GetPowerInfo', { params: data })
}
/**
 * 获取实时数据信息
 */
export const GetRealTimeDataPageList = (data) => {
    return $http.get('/api/DataRecord/GetRealTimeDataPageList', { params: data })
}
/**
 * 获取历史数据信息
 */
export const GetHistoryDataPageList = (data) => {
    return $http.get('/api/DataRecord/GetHistoryDataPageList', { params: data })
}
/**
 * 获取历史数据信息
 */
export const GetHistoryDataList = (data) => {
    return $http.get('/api/DataRecord/GetHistoryDataList', { params: data })
}
/**
 * 下载指定时间段的历史记录
 */
export const ExportRecord = (data) => {
    return $http.get('/api/DataRecord/ExportRecord', { params: data, responseType: 'blob' })
}
/**
 * 分页获取报警数据信息
 */
export const GetAlarmDataPageList = (data) => {
    return $http.get('/api/DataRecord/GetAlarmDataPageList', { params: data })
}
/**
 * 分页获取故障数据信息
 */
export const GetFaultDataPageList = (data) => {
    return $http.get('/api/DataRecord/GetFaultDataPageList', { params: data })
}
/**
 * 获取报警数据信息
 */
export const GetAlarmDataList = (data) => {
    return $http.get('/api/DataRecord/GetAlarmDataList', { params: data })
}
/**
 * 获取故障数据信息
 */
export const GetFaultDataList = (data) => {
    return $http.get('/api/DataRecord/GetFaultDataList', { params: data })
}
/**
 * 获取组态页面数据
 */
export const GetSCADARealTimeData = (data, headers) => {
    return $http.get('/api/DataRecord/GetSCADARealTimeData', { params: data, headers: headers })
}
/**
 * 修改点位值信息
 */
export const SetParamesValue = (data, headers) => {
    return $http.put('/api/DataRecord/SetParamesValue', null, { params: data, headers: headers })
}